.tim-row {
  margin-bottom: 20px;
}

.tim-white-buttons {
  background-color: #777777;
}

.typography-line {
  padding-left: 25%;
  margin-bottom: 35px;
  position: relative;
  display: block;
  width: 100%;
}

.pick-class-label {
  border-radius: 8px;
  border: 1px solid #E3E3E3;
  color: #ffffff;
  cursor: pointer;
  display: inline-block;
  font-size: 75%;
  font-weight: bold;
  line-height: 1;
  margin-right: 10px;
  padding: 23px;
  text-align: center;
  vertical-align: baseline;
  white-space: nowrap;
}

.offline-doc .page-header {
  display: flex;
  align-items: center;
}

.typography-line span {
  bottom: 10px;
  color: #c0c1c2;
  display: block;
  font-weight: 400;
  font-size: 13px;
  line-height: 13px;
  left: 0;
  margin-left: 20px;
  position: absolute;
  width: 260px;
  text-transform: none;
}

.tim-row {
  padding-top: 60px;
}

.tim-row h3 {
  margin-top: 0;
}

.switch {
  margin-right: 20px;
}

#navbar-full .navbar {
  border-radius: 0 !important;
  margin-bottom: 15px;
  z-index: 2;
}

#menu-dropdown .navbar {
  border-radius: 3px;
}

#pagination-row .pagination-container {
  height: 100%;
  max-height: 100%;
  display: flex;
  align-items: center;
}

#icons-row i.now-ui-icons {
  font-size: 30px;
}

.space {
  height: 130px;
  display: block;
}

.space-110 {
  height: 110px;
  display: block;
}

.space-50 {
  height: 50px;
  display: block;
}

.space-70 {
  height: 70px;
  display: block;
}

.navigation-example .img-src {
  background-attachment: scroll;
}

.navigation-example {
  background-position: center center;
  background-size: cover;
  margin-top: 0;
  min-height: 740px;
  height: 100%;
}

#notifications {
  background-color: #FFFFFF;
  display: block;
  width: 100%;
  position: relative;
}

.tim-note {
  text-transform: capitalize;
}

#buttons .btn,
#javascriptComponents .btn {
  margin: 0 0px 10px;
}

.space-100 {
  height: 100px;
  display: block;
  width: 100%;
}

.be-social {
  padding-bottom: 20px;
  /*     border-bottom: 1px solid #aaa; */
  margin: 0 auto 40px;
}

.txt-white {
  color: #FFFFFF;
}

.txt-gray {
  color: #ddd !important;
}

.parallax {
  width: 100%;
  height: 570px;
  display: block;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.logo-container .logo {
  overflow: hidden;
  border-radius: 50%;
  border: 1px solid #333333;
  width: 50px;
  float: left;
}

.logo-container .brand {
  font-size: 16px;
  color: #FFFFFF;
  line-height: 18px;
  float: left;
  margin-left: 10px;
  margin-top: 7px;
  width: 70px;
  height: 40px;
  text-align: left;
}

.logo-container .brand-material {
  font-size: 18px;
  margin-top: 15px;
  height: 25px;
  width: auto;
}

.logo-container .logo img {
  width: 100%;
}

.navbar-small .logo-container .brand {
  color: #333333;
}

.fixed-section {
  top: 90px;
  max-height: 80vh;
  overflow: scroll;
  position: sticky;
}

.fixed-section ul {
  padding: 0;
}

.fixed-section ul li {
  list-style: none;
}

.fixed-section li a {
  font-size: 14px;
  padding: 2px;
  display: block;
  color: #666666;
}

.fixed-section li a.active {
  color: #00bbff;
}

.fixed-section.float {
  position: fixed;
  top: 100px;
  width: 200px;
  margin-top: 0;
}

.parallax .parallax-image {
  width: 100%;
  overflow: hidden;
  position: absolute;
}

.parallax .parallax-image img {
  width: 100%;
}

@media (max-width: 768px) {
  .parallax .parallax-image {
    width: 100%;
    height: 640px;
    overflow: hidden;
  }
  .parallax .parallax-image img {
    height: 100%;
    width: auto;
  }
}

/*.separator{
    content: "Separator";
    color: #FFFFFF;
    display: block;
    width: 100%;
    padding: 20px;
}
.separator-line{
    background-color: #EEE;
    height: 1px;
    width: 100%;
    display: block;
}
.separator.separator-gray{
    background-color: #EEEEEE;
}*/

.social-buttons-demo .btn {
  margin-right: 5px;
  margin-bottom: 7px;
}

.img-container {
  width: 100%;
  overflow: hidden;
}

.img-container img {
  width: 100%;
}

.lightbox img {
  width: 100%;
}

.lightbox .modal-content {
  overflow: hidden;
}

.lightbox .modal-body {
  padding: 0;
}

@media screen and (min-width: 991px) {
  .lightbox .modal-dialog {
    width: 960px;
  }
}

@media (max-width: 991px) {
  .fixed-section.affix {
    position: relative;
    margin-bottom: 100px;
  }
}

@media (max-width: 768px) {
  .btn,
  .btn-morphing {
    margin-bottom: 10px;
  }
  .parallax .motto {
    top: 170px;
    margin-top: 0;
    font-size: 60px;
    width: 270px;
  }
}

/*       Loading dots  */

/*      transitions */

.presentation .front,
.presentation .front:after,
.presentation .front .btn,
.logo-container .logo,
.logo-container .brand {
  -webkit-transition: all .2s;
  -moz-transition: all .2s;
  -o-transition: all .2s;
  transition: all .2s;
}

#images h4 {
  margin-bottom: 30px;
}

#javascriptComponents {
  padding-bottom: 0;
}

/*      layer animation          */

.layers-container {
  display: block;
  margin-top: 50px;
  position: relative;
}

.layers-container img {
  position: absolute;
  width: 100%;
  height: auto;
  top: 0;
  left: 0;
  text-align: center;
}

.animate {
  transition: 1.5s ease-in-out;
  -moz-transition: 1.5s ease-in-out;
  -webkit-transition: 1.5s ease-in-out;
}

.navbar-default.navbar-small .logo-container .brand {
  color: #333333;
}

.navbar-transparent.navbar-small .logo-container .brand {
  color: #FFFFFF;
}

.navbar-default.navbar-small .logo-container .brand {
  color: #333333;
}

.sharing-area {
  margin-top: 80px;
}

.sharing-area .btn {
  margin: 15px 4px 0;
}

.section-thin,
.section-notifications {
  padding: 0;
}

.section-navbars {
  padding-top: 0;
}

#navbar .navbar {
  margin-bottom: 20px;
}

#navbar .navbar-toggler,
#menu-dropdown .navbar-toggler {
  pointer-events: none;
}

.section-tabs {
  background: #EEEEEE;
}

.section-pagination {
  padding-bottom: 0;
}

.section-download {
  padding-top: 130px;
}

.section-download .description {
  margin-bottom: 60px;
}

.section-download h4 {
  margin-bottom: 25px;
}

.section-examples a {
  text-decoration: none;
}

.section-examples a+a {
  margin-top: 30px;
}

.section-examples h5 {
  margin-top: 30px;
}

.components-page .wrapper>.header,
.tutorial-page .wrapper>.header {
  height: 500px;
  padding-top: 128px;
  background-size: cover;
  background-position: center center;
}

.components-page .title,
.tutorial-page .title {
  color: #FFFFFF;
}

.brand .h1-seo {
  font-size: 2.8em;
  text-transform: uppercase;
  font-weight: 300;
}

.brand .n-logo {
  max-width: 100px;
  margin-bottom: 40px;
}

.invision-logo {
  max-width: 70px;
  top: -2px;
  position: relative;
}

.creative-tim-logo {
  max-width: 140px;
  top: -2px;
  position: relative;
}

.section-javascript .title {
  margin-bottom: 0;
}

.navbar .switch-background {
  display: block;
}

.navbar-transparent .switch-background {
  display: none;
}

.section-signup .col .btn {
  margin-top: 30px;
}

#buttons-row .btn {
  margin-bottom: 10px;
}

.section-basic {
  padding-top: 0;
}

.section-images {
  padding-bottom: 0;
}

@media screen and (max-width: 991px) {
  .section-navbars .navbar-collapse {
    display: none !important;
  }
}

/* EPI-HAZ Nav Spec Styles */

.nav-product-main-title {
  color: rgb(75, 89, 118);
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  font-family: Montserrat;
  font-size: 22px;
  line-height: 1.273em;
  font-weight: 600;
}

.nav-product-main-description {
  color: rgb(75, 89, 118);
  font-family: Montserrat;
  font-size: 14px;
  line-height: 1.714em;
  font-weight: 400;
}

.nav-product-title {
  color: #0A60FF !important;
  font-size: 20px !important;
  font-weight: 700 !important;
  font-family: Montserrat !important;
  margin-bottom: 0 !important;
}

.nav-product-description {
  color: rgb(75, 89, 118);
  font-family: Montserrat;
  font-size: 13px;
  line-height: 1.714em;
  font-weight: 400;
}

.nav-product-hover-button {
  height: 120px !important;
  border-radius: 5px !important;
  margin-bottom: 0px !important;
  color: rgb(5, 97, 255) !important;
}

.nav-product-hover-button-title {
  margin-bottom: 0rem;
  color: rgb(5, 97, 255);
  font-family: Montserrat;
  font-size: 12px;
  line-height: 1.571em;
  font-weight: 600;
}

.nav-product-hover-button-size {
  font-size: 10px !important;
  font-weight: 700;
  font-family: Montserrat !important;
  color: #949CAD !important;
  margin-bottom: 0rem !important;
}

.nav-product-hover-button-info {
  font-size: 9px !important;
  font-weight: 400;
  font-family: Montserrat !important;
  color: #949CAD !important;
  margin-bottom: 0rem !important;
}

.nav-product-menu-inside {
  color: rgb(75, 89, 118);
  font-size: 12px;
  font-weight: 700;
  font-family: Montserrat;
  margin-bottom: 0 !important;
}

.nav-product-menu-outside {
  color: rgb(75, 89, 118);
  font-size: 12px;
  font-weight: 700;
  font-family: Montserrat;
  margin-bottom: 0 !important;
}

.nav-custom-order-title {
  color: rgb(75, 89, 118);
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  font-family: Montserrat;
  font-size: 18px;
  line-height: 1.273em;
  font-weight: 600;
}

.HelpMeChoosePagination {
  border: 2 !important;
  min-width: 50px !important;
  min-height: 50px !important;
  margin: 0 35px !important;
  padding: 0px 20px !important;
  line-height: 50px !important;
  font-size: 19px !important;
}

.HelpMeChoosePaginationMobile {
  border: 2 !important;
  min-width: 50px !important;
  min-height: 50px !important;
  margin: 0 3px !important;
  padding: 0px 17px !important;
  line-height: 50px !important;
  font-size: 19px !important;
}

.anyClass {
  /* min-height: 75vh;
  max-height: 740px;
  overflow-y: scroll; */
}

.cNavbar {
  margin-top: 60px !important;
  border-top: 1px solid rgb(214, 228, 255) !important;
  box-shadow: 0px 5px 10px 0px rgb(0 0 0 / 15%) !important;
}

.CustomizerPage {
  background: rgb(235, 242, 255) !important;
  padding-bottom: 50px !important;
}

.CustomizerPageMobile {
  background: rgb(235, 242, 255) !important;
  padding-bottom: 0px !important;
}

.stickyFooter {
  /* position: fixed; */
  position: sticky;
  width: 100%;
  bottom: 0;
  border-top:1px solid white;
  color: #fff;
  background-color: rgb(5, 97, 255) !important;
}

.questionToolTip {
  background-color: rgb(214, 228, 255);
  color: rgb(5, 97, 255);
  margin: 5px;
}

.epiInputSize {
  border-radius: 6px;
}

.epiTextArea {
  border: 1px solid #E3E3E3 !important;
  border-radius: 6px !important;
}

.customizerInputTitle {
  font-weight: 700;
  font-size: 14px;
  text-transform: uppercase !important;
  line-height: 22px;
  color: #4B5976;
}

.customizerInputTitleSmallX1 {
  font-weight: 700;
  font-size: 12px;
  text-transform: capitalize !important;
  line-height: 22px;
  color: #4B5976;
}

.customizerInputTitleProcessParameters {
  font-weight: 700;
  font-size: 11px;
  text-transform: uppercase !important;
  line-height: 22px;
  color: #4B5976;
}

.processParametterCols {
  padding-right: 5px !important;
  padding-left: 5px !important;
}

.inputPowerCommunicationCols {
  padding-right: 0px !important;
  padding-left: 20px !important;
}

.inputPowerCommunicationNav {
  background-color: rgb(248, 250, 253);
  min-height: 38px;
  margin-top: 3px;
  box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px;
  padding-right: 40px;
  padding-left: 0px;
}

.inputPowerCommunicationTextbox {
  border: 1px solid rgb(5, 97, 255);
  border-radius: 5px;
  padding: 5px;
  background-color: rgb(214, 228, 255);
  color: rgb(5, 97, 255);
}

.inputErrorTextbox {
  border: 1px solid rgb(246 65 81);
  border-radius: 5px;
  padding: 5px;
  min-height: 56px;
  background-color: rgb(252 207 211);
  color: rgb(246 65 81);
}

.errorText {
  color: rgb(246 65 81);
}

.footerTitle {
  color: rgb(71, 136, 255);
  font-weight: 700;
  font-size: 18px;
}

.footerLink {
  margin: 0px !important;
  padding: 0px !important;
  margin-bottom: 1rem !important;
}

.footerNavEpi {
  color: white;
  font-weight: 700;
  font-size: 16px;
  margin-top: 15px;
}

.footerLogosContainer {
  height: 210px;
  align-items: center !important;
}

.footerImgLogos {
    width: auto;
    height: auto;
}

.footerContactInfo {
  background-color: rgb(1, 46, 130) !important;
  height: 300px;
  color: white;
}

.footerContactInfoTitle {
  color:#B7BDC8 !important;
  font-weight: 700;
  font-size: 14px;
  text-transform: uppercase !important;
}

.footerContactInfoText a {
    font-size: 18px;
    font-weight: 700;
    color: white;
    line-height: 30px;
}

.footerContactInfoTextGray {
  font-size: 16px;
  font-weight: 400;
}

.footerContactInfoSocialImgs {
    width: 90%;
    height: 100%;
    max-width: 60px;
    max-height: 60px;
    display: inline-flex;
    justify-content: center;
}

.footerBlack {
  background-color: black !important;
  color: white !important;
}

.footerBlackText {
  color:#B7BDC8 !important;
  font-weight: 400;
  font-size: 14px;
}

.footerBlackCopyright {
  color: white !important;
  font-weight: 400;
  font-size: 14px;
}

.footerMobileListGroupItem {
  background: #012E82 !important;
  color: white !important;
}

.footerDropDownLinkIcon {
  float: right;
  overflow: visible;
  font-size: 22px;
  line-height: 21px;
}

.saveQuoteButtonZoom {
  padding: 50px;
  transition: transform .2s; /* Animation */
}

.saveQuoteButtonZoom:hover {
  transform: scale(1.1); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}

html {
  scroll-behavior: smooth;
}

.menuHeaderTopShadow {
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
}

.customizerCard {
  height: 10px;
  margin-bottom: 5px;
  border-top-left-radius: calc(0.45rem - 1px);
  border-top-right-radius: calc(0.45rem - 1px);
}

.customizerPipeSizeError {
  font-weight: 700;
  /* font-size: 20px; */
  text-transform: uppercase !important;
  line-height: 22px;
  color: rgb(246 65 81);
}

.containerTest {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.capitalize {
  text-transform: uppercase;
}

.epi-blue {
  color: #0A60FF !important;
}
