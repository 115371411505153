.logoPrimary {
  max-width: 100px;
}

a {
    text-decoration: none;
}

a:hover {
    text-decoration: none;
}

.form-check-input {
    background-color: #F3F7FF;
    cursor: pointer;
}

.card {
    border: 0px solid transparent !important;
    /* box-shadow: 0px 0px; */
}

.card-shadow-none {
    box-shadow: 0px 0px;
}

.form-select {
    display: block;
    width: 100%;
    padding: 10px;
    -moz-padding-start: calc(0.75rem - 3px);
    font-size: 0.8571em;
    font-weight: 400;
    line-height: 12px;
    color: #212529;
    background-color: #fff;
    background-image: url(data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e);
    background-repeat: no-repeat;
    background-position: right 0.75rem center;
    background-size: 16px 12px;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

select::before {
  position: absolute;
  top: -7px;
  left: 9px;
  display: inline-block;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #CCC;
  border-left: 7px solid transparent;
  border-bottom-color: rgba(0, 0, 0, 0.2);
  content: '';
}

select::after {
  position: absolute;
  top: -6px;
  left: 10px;
  display: inline-block;
  border-right: 6px solid transparent;
  border-bottom: 6px solid white;
  border-left: 6px solid transparent;
  content: '';
}

/* common background color */
.background-colorOne {
    background-color: #EBF2FF;
}

/* common-icon img */
.common-icon-img {
    width: 50px;
    height: 50px;
    object-fit: contain;
}

/* common-icon img */

/* common background color */

/* common header text */
.commontitleOne {
    font-size: 24px;
    font-weight: var(--main-font-weight);
    font-family: var(--font-family);
    color: #4A4A6B;
    margin: 0;
    margin-bottom: 10px !important;
}

.commontitleTwo {
    font-size: 28px;
    font-weight: var(--main-font-weight);
    font-family: var(--font-family);
    color: #4A4A6B;
}

.commontitlethree {
    font-size: 20px;
    font-weight: 700 !important;
    font-family: var(--font-family);
    color: #000 !important;
    margin-bottom: 10px !important;
}

.commontitlefour {
    font-size: 22px;
    font-weight: var(--main-font-weight);
    font-family: var(--font-family);
    color: var(--main-color);
}

.commontitlefive {
    font-size: 20px;
    font-weight: 600;
    font-family: var(--font-family);
    color: #4A4A6B;
    margin: 0;
}

.commonParaOne {
    font-size: 18px;
    font-weight: var(--main-font-weight);
    font-family: var(--font-family);
    color: var(--main-color);
}

.commonParaTwo {
    font-size: 15px;
    font-weight: var(--main-font-weight);
    font-family: var(--font-family);
    color: #4B5976;
}

.commonParaThree {
    font-size: 15px;
    font-weight: 500;
    font-family: var(--font-family);
    color: #4B5976;
}

.commonParaFour {
    font-size: 15px;
    font-weight: 500;
    font-family: var(--font-family);
    height: 100px;
    color: #4B5976;
}

/* common header text */
/* all button start */
.downloadButton {
    background: linear-gradient(90deg, rgba(9, 92, 246, 1) 2%, rgba(1, 66, 188, 1) 74%);
    color: #fff;
    margin-top: 20px;
    margin-bottom: 20px;
    border: 0;
    border-radius: 5px;
    font-weight: bold;
}

.downloadButton {
    color: #fff !important;
}

.button1 {
    background: linear-gradient(90deg, rgba(9, 92, 246, 1) 2%, rgba(1, 66, 188, 1) 74%);
    color: #fff;
    margin-top: 20px;
    margin-bottom: 20px;
    border: 0;
    border-radius: 5px;
    padding: 15px 30px;
    font-weight: bold;
}

.button1 {
    color: #fff !important;
}

.button2 {
    color: var(--main-color);
    margin-top: 20px;
    margin-bottom: 20px;
    border: 1px solid var(--main-color);
    border-radius: 5px;
    padding: 15px 30px;
    font-weight: bold;
    /* margin-left: 20px; */
    background-color: transparent;
}

.button2 {
    color: var(--main-color) !important;
}

.button2:hover {
    color: #fff !important;
    background: linear-gradient(90deg, rgba(9, 92, 246, 1) 2%, rgba(1, 66, 188, 1) 74%);
}

.button3 {
    color: var(--main-color);
    margin-top: 20px;
    margin-bottom: 20px;
    border-radius: 5px;
    padding: 15px 30px;
    font-weight: bold;
    /* margin-left: 20px; */
    background-color: transparent;
}

.button3:hover {
    color: #fff !important;
    background: linear-gradient(90deg, rgba(9, 92, 246, 1) 2%, rgba(1, 66, 188, 1) 74%);
}

/* button end */
/* radio button design */
.alp-radio-container {
    display: block;
    margin-top: 15px;
    position: relative;
    padding-left: 35px;
    margin-bottom: 0px;
    cursor: pointer;
    /* font-size: 18px; */
    -webkit-user-select: none;
    user-select: none;
    /* color: #949CAD; */
    font-weight: 500;
}

/* Hide the browser's default radio button */
.alp-radio-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

.checkmark {
    position: absolute;
    top: 0;
    left: 0px;
    height: 18px;
    width: 18px;
    background-color: #EBF2FF;
    border-radius: 50%;
    border: 1px solid #B7BDC8;
}

.alp-radio-container:hover input~.checkmark {
    background-color: #EBF2FF;
}

.alp-radio-container input:checked~.checkmark {
    background-color: #095CF6;
}

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.alp-radio-container input:checked~.checkmark:after {
    display: block;
}

.alp-radio-container .checkmark:after {
    top: 0px;
    left: 0px;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: var(--main-color);
}

/* radio button design */

/* mui accordation color */
.MuiPaper-root.MuiPaper-elevation.MuiPaper-elevation0.MuiAccordion-root.css-1fjvggn-MuiPaper-root-MuiAccordion-root {
    margin: 10px 0px;
    border-radius: 5px !important;
}

.MuiAccordionSummary-root {
    background-color: #0A60FF !important;
    color: #fff !important;
}

.MuiAccordionDetails-root {
    font-size: 15px !important;
    font-weight: 400 !important;
    font-family: var(--font-family) !important;
    color: #4B5976 !important;
}

.MuiSvgIcon-root {
    font-size: 20px !important;
    color: #fff !important;
}

/* mui accordation color */



/* pages design goes here ------------------------------------------------------------------ */
/* ****************************************************************************************** */
/* ****************************************************************************************** */
/* ****************************************************************************************** */

.allProductsLink {
    font-size: 1rem;
    font-weight: 600;
    color: white;
    padding: 10px;
}

.allProductsLink:hover {
    color: #012e82;
}

/* Footer Styles */

.footer {
    padding: 35px 0px;
}

li.footerLogosNavLink.nav-item {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
}

.footerSocialIconRow.row {
    padding-right: calc(var(--bs-gutter-x) * .5);
    padding-left: calc(var(--bs-gutter-x) * .5);
}

.footerSocialIcon.col {
    width: 100%;
    display: block;
    height: 60px;
    padding: 0;
}

div#copyright {
    text-align: right;
}

img.img-raised.footerCopyrightLogo {
    width: 100%;
    padding-bottom: 5px;
    max-width: 75px;
}


/* sidebar for all products only start */
.SidebarAllproducts span {
    font-weight: 500;
    font-family: var(--font-family);
    color: #949CAD;
}

.SidebarAllproducts h5 {
    font-size: 15px;
}

.catalog-img {
    width: 175px;
    box-shadow: 0px 0px 13px 0px grey;
    border-radius: 5px;
    margin-top: 25px;
}

.download-cata-p {
    font-weight: 500;
    color: var(--main-color);
    width: 80%;
    margin-top: 20px;
}

.specs-icon img {
    height: 30px;
    padding-right: 10px;
}

/* sidebar for all products only end */

/* allproducts start */
.ap-body-main {
    min-height: 1200px;
}

.ap-section-1 {
    background-image: url('../../assets/img/images/aboutUs-coverpage.png');
    height: 500px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    position: relative;
    background-color: transparent;
}

.ap-section-1 .image-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    color: #fff;
    background-image: url(https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Products%2FAll%2FHero%2Fall-products-hero.png?alt=media&token=56797ce8-35ab-406e-aba9-373df6f12fcb);
    background-size: cover;
}

.ap-section-1 .cover-title-h2 {
    font-weight: 700;
    margin-bottom: 10px;
    text-align: center;
}

.ap-section-1 .cover-title-p {
    color: #fff !important;
    margin: 0;
    text-align: center;
}

.meter-name {
    font-weight: 600 !important;
    font-size: 18px !important;
    color: #000 !important;
    text-align: center;
}

.ap-custom-row {
    row-gap: 30px;
}

.ap-bm-child-2 {
    background-color: #F8FAFD !important;
}

.ap-section-3 {
    background-color: #EBF2FF;
}

/* allproducts end */
/* How to videos start */
.htv-section-1 {
    background-image: url('../../assets/img/images/howtovideos-banner-img.png');
    height: 500px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    position: relative;
    color: #fff;
    z-index: 10;
}

.htv-section-1::after {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    background: #0059ff;
    opacity: 0.5;
    z-index: -1;
}

.htv-section-1 .image-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    color: #fff;
}

.htv-section-1 .cover-title-h2 {
    font-weight: 700;
    margin-bottom: 10px;
    text-align: center;
}

.htv-section-1 .cover-title-p {
    color: #fff !important;
    margin: 0;
    text-align: center;
}

.meter-name {
    font-weight: 600 !important;
    font-size: 18px !important;
    color: #000 !important;
    text-align: center;
}

.htv-card-title {
    font-size: 18px;
    font-weight: 700;
    margin: 15px 0px;
    text-align: start;
    color: #000;
}

/* How to videos end */

/* downloads start */
.dl-section-1 {
    background-image: url('../../assets/img/images/downloads-banner-img.png');
    height: 500px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    position: relative;
    color: #fff;
    z-index: 10;
}

.dl-section-1::after {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    background: #0059ff;
    opacity: 0.5;
    z-index: -1;
}

.dl-section-1 .image-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    color: #fff;
}

.dl-section-1 .cover-title-h2 {
    font-weight: 700;
    margin-bottom: 10px;
    text-align: center;
}

.dl-section-1 .cover-title-p {
    color: #fff !important;
    margin: 0;
    text-align: center;
}

.dl-bm-child-2 {
    background-color: #fff !important;
}

.Sidebardownload {
    width: 332px;
}

.Sidebardownload h5 {
    color: var(--main-color);
    font-size: 15px;
}

.Sidebardownload p {
    font-size: 15px;
    font-weight: 600;
    margin-left: 0;
    color: #4B5976;
}

@media only screen and (max-width: 993px) {
    .mobile-sidebar .sidebar-main {
        background-color: #EBF2FF;
    }
}

.dl-card-img {
    width: 130px;
}

.dl-custom-row {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 20px;
}

@media only screen and (max-width: 900px) {
    .dl-custom-row {
        grid-template-columns: 1fr 1fr;
    }
}

@media only screen and (max-width: 600px) {
    .dl-custom-row {
        grid-template-columns: 1fr;
    }
}

.dl-custom-row select {
    color: #949CAD;
}

/* downloads end */

/* FAQ start */
.faq-section-1 {
    background-image: url('../../assets/img/images/faqs-banner-img.png');
    height: 500px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    position: relative;
    color: #fff;
    z-index: 10;
}

.faq-section-1::after {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    background: #0059ff;
    opacity: 0.5;
    z-index: -1;
}

.faq-section-1 .image-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    color: #fff;
}

.faq-section-1 .cover-title-h2 {
    font-weight: 700;
    margin-bottom: 10px;
    text-align: center;
}

.faq-section-1 .cover-title-p {
    color: #fff !important;
    margin: 0;
    text-align: center;
}

.fq-accordation {
    position: relative;
    background-color: #4788FF;
    padding: 10px;
    cursor: pointer;
    border-radius: 5px 5px 0px 0px;
}

.accordation-text {
    margin: 0;
    color: #fff;
    margin-left: 40px;
    font-size: 18px;
}

.accrodation-right-icon {
    position: absolute;
    top: 14px;
    font-size: 18px;
    color: #fff;
    left: 24px;
    transition: all 0.3s;
}

#accordation-body-hide {
    display: hide;
}

.accordation-body {
    background-color: #fff;
    border: 1px solid #D6E4FF;
    border-radius: 0px 0px 5px 5px;
    padding: 20px;
    transition: all 1s;
}

.accordation-body p {
    font-weight: 400;
    color: #4B5976;
}

/* FAQ end */

/* rma form start */

.rf-form-check-input {
    font-size: 31px;
    margin-left: -34px !important;
    margin-top: 11px;
}

.rf-form-check-input {
    font-size: 20px;
}

.rf-form-check-label {
    font-size: 18px;
    margin: 0px !important;
}

.rf-button {
    margin: 0;
    padding: 14px 20px;
    margin-bottom: 9px;
}
/* rma form end */

/* product start */
.pt-img {
    width: 70px;
    height: 70px;
    object-fit: contain;
}

.pt-title {
    color: var(--main-color) !important;
    font-weight: 600 !important;
    font-size: 16px;
    margin: 0;
}

.pt-body {
    font-size: 14px;
    font-weight: 600 !important;
    margin: 0;
}

.pt-right-text {
    font-size: 14px;
}

/* product end */

/* Meter Models */
.mm-section-1 {
    background-image: url('../../assets/img/images/aboutUs-coverpage.png');
    height: 500px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    position: relative;
    color: #fff;
    z-index: 10;
}

.mm-section-1::after {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    background: #0059ff;
    opacity: 0.5;
    z-index: -1;
}

.mm-section-1 .image-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    color: #fff;
}

.mm-section-1 .cover-title-h2 {
    font-weight: 700;
    margin-bottom: 10px;
    text-align: center;
}

.mm-section-1 .cover-title-p {
    color: #fff !important;
    margin: 0;
    text-align: center;
}

.mm-section-2 {
    background-color: #F8FAFD;
}

.mm-row {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 20px;
}

@media only screen and (max-width: 900px) {
    .mm-row {
        grid-template-columns: 1fr 1fr;
    }
}

@media only screen and (max-width: 600px) {
    .mm-row {
        grid-template-columns: 1fr;
    }
}

.mm-card-title {
    color: var(--main-color);
    font-weight: 700;
    font-size: 25px;
    margin-bottom: 10px;
}

.mm-card-des {
    color: #B7BDC8;
    font-weight: 400;
    font-size: 14px;
}

.mm-card-body {
    color: #4B5976;
    font-weight: 500;
    font-size: 16px;
    padding: 0px 10px;
    margin-bottom: 50px;
}

.mm-card-btn {
    padding: 10px 50px;
    border: 2px solid var(--main-color);
}

.mm-s4-title {
    color: #000;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 10px;
}

.mm-s4-des {
    color: #4B5976 !important;
    font-size: 13px;
    line-height: 22px;
    font-weight: 500;
}

.mm-section-4 {
    background-color: #EBF2FF;
    padding: 50px 0px;
}

.mm-textarea {
    background-color: #fff;
}

.mm-card2-title {
    font-size: 20px;
    color: var(--main-color);
    font-weight: 600;
    margin-bottom: 5px;
}

.mm-card2-des {
    font-weight: 400;
    font-size: 15px;
}

.mm-card2-img {
    width: 70px;
    height: 70px;
    object-fit: contain;
}

.mm-img-main-div {
    height: 120px;
    width: 120px;
}

.mm-row-2 {
    row-gap: 20px;
}

.mm-section-6 {
    padding-bottom: 50px;
}

/* Meter Models */

/* epi val start */
.ev-des {
    color: #4B5976 !important;
    font-size: 13px;
    line-height: 22px;
    font-weight: 500;
}

.ev-card-inline-text {
    color: var(--main-color);
    font-weight: 600;
    margin: 0;
    font-size: 15px;
}

.ev-card-measurement {
    font-weight: 500;
    font-style: italic;
    font-size: 13px;
    margin: 0;
    color: #949CAD;
}

.ev-line-size {
    color: #B7BDC8;
    font-weight: 500;
    margin: 0;
    font-size: 15px;
    margin-bottom: 5px;
}

.ev-s2-card-img {
    width: 50px;
    height: 60px;
    margin: 10px auto;
    object-fit: contain;
}

.ev-s2-row {
    width: 80%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 110px 110px;
    grid-column-gap: 10px;
    justify-content: center;
}

.ev-s2-col {
    padding: 0px 15px;
    background-color: #d6e4ff69;
    border: 2px solid #D6E4FF;
    border-radius: 5px;
}

/* epi val end */

/* TechSpecs start */
.ts-section-1 {
    background-image: url('../../assets/img/images/techSpecs-banner-img.png');
    height: 500px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    position: relative;
    color: #fff;
    z-index: 10;
}

.ts-section-1::after {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    background: #0059ff;
    opacity: 0.5;
    z-index: -1;
}

.ts-section-1 .image-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    color: #fff;
}

.ts-meter-img {
    position: absolute;
    bottom: 0;
    width: 500px;
}

/* TechSpecs end */

/* Tech Speces start */
@media only screen and (max-width: 600px) {
    .menuOne {
        height: auto !important;
    }

    .ts-menu-row {
        justify-content: center;
    }
}

.menuOne {
    height: 100px;
    background-color: var(--main-color);
    position: relative;
    bottom: 50px;
    z-index: 11;
}

.menuOne a {
    color: #fff;
    font-size: 15px;
}

.ts-menu-icon {
    width: 50px;
    object-fit: contain;
}

.tec-icon {
    width: 40px;
}

.menuOne span {
    margin-bottom: 10px;
}

.ts-menu-left {
    font-weight: 600;
    font-size: 25px;
    margin: 0;
    color: #fff;
}

.ts-menu-title-left {
    font-weight: 400;
    font-size: 13px;
    margin: 0;
    color: #fff;
}

.second-menu {
    display: grid;
    height: 60px;
    align-items: center;
    justify-content: center;
    background-color: #F8FAFD;
}

.second-menu ul {
    list-style-type: none;
    margin: 0;
}

.second-menu ul li {
    display: inline;
    margin-right: 10px;
}

.second-menu ul li:hover {
    color: var(--main-color);
}

.second-menu ul li a {
    color: #4A4A6B;
    font-weight: 600;
    font-size: 15px;
    display: inline-block;
    position: relative;
}

.second-menu ul li a:hover, .active-nav {
    color: var(--main-color) !important;
}

.second-menu ul li>a::after {
    content: "";
    display: block;
    margin: 0px auto;
    height: 3px;
    width: 0;
    top: 5px;
    background: transparent;
    transition: all 0.3s;

}

.second-menu ul li>a::after, .second-menu ul li>a.active-nav::after {
    width: 100%;
    background: #fff;
    color: var(--main-color)
}

.second-menu ul li>a:hover::after, .second-menu ul li>a.active-nav::after {
    width: 100%;
    background: blue;
}

.ts-section-4 {
    background-color: #EBF2FF;
}

.ts-section-5 {
    margin: 50px 0px;
}

.ts-s5-title {
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 5px;
}

.ts-s5-body {
    font-size: 14px;
    font-weight: 500;
}

.ts-s6-des-p {
    font-size: 14px;
}

.ts-s6-table {
    background-color: #fff;
    font-size: 15px;
    font-weight: 400;
    font-family: var(--font-family);
    color: #4B5976;
}

.ts-s6-table td:nth-child(even) {
    background-color: #F8FAFD;
}

.ts-s6-table thead {
    background-color: var(--main-color);
    color: #fff;
}

.table thead th {
    vertical-align: middle;
    border-bottom: 2px solid #dee2e6;
}

.ts-s6-table td {
    text-align: center;
    font-size: 20px;
    color: var(--main-color);
}

.ts-section7 {
    background-color: #F8FAFD;
}

.ts-s7-title {
    color: var(--main-color);
    font-weight: 600;
    font-size: 15px;
    margin: 0;
}

.ts-s7-des {
    font-weight: 500;
    font-size: 13px;
    margin: 0;
}

.ts-s7-inner-row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
}

.ts-s7-inner-col {
    border: 2px solid transparent;
    padding: 10px;
    cursor: pointer;
}

.ts-s7-inner-col:first-child {
    border: 2px solid var(--main-color);
    border-radius: 5px;
    background-color: #fff;
    padding: 10px;
    box-shadow: 0px 0px 10px -3px grey;
}

.ts-s7-inner-col:hover {
    border: 2px solid var(--main-color);
    border-radius: 5px;
    background-color: #fff;
    padding: 10px;
    box-shadow: 0px 0px 10px -3px grey;
}

.shadow {
    box-shadow: 0px 0px 10px -3px grey !important;
    padding: 0;
}

.ts-section-8 {
    background-color: #EBF2FF;
}

.ts-s9-p {
    font-weight: 500;
}

img.ts-s9-img {
    width: 300px;
    height: 300px;
    border-radius: 50%;
}

@media only screen and (max-width: 1189px) {
    .ts-s9-row {
        flex-direction: column-reverse !important;
        row-gap: 20px;
    }

    .ts-s9-btn-row {
        row-gap: 5px;
    }

    .ts-s9-btn-row>div {
        display: flex;
        justify-content: center;
    }
}

.ts-s10-img {
    width: 60%;
}

.ddacordation-row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 0px;
    grid-column-gap: 20px;
}

@media only screen and (max-width: 900px) {
    .ddacordation-row {
        grid-template-columns: 1fr;
    }
}

.ts-tbn-row {
    width: 91%;
}

.ddacordation-table th, td {
    font-size: 14px;
    padding: 3px 0px;
}

.ddacordation-table td:nth-child(1) {
    color: var(--main-color);
    font-weight: 600;
}

/* Tech Speces end */

/* cadfiles */
.cadf-table {
    width: 100%;
}

.cadf-table thead {
    background-color: var(--main-color);
    color: #fff;
}

.cadf-table th {
    padding: 20px;
}

.cadf-table td {
    border-bottom: 2px solid #E2E2E2;
    padding: 20px;
}

.cadf-icon {
    font-size: 25px;
    color: var(--main-color);
}

/* cadfiles */

/* suport */
.sprt-section-1 {
    background-image: url('../../assets/img/images/supoort-banner.png');
    height: 500px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    position: relative;
    color: #fff;
    z-index: 1;
}

.sprt-section-1::after {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    background: #0059ff;
    opacity: 0.5;
    z-index: -1;
}

.sprt-section-1 .image-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    color: #fff;
}

.sprt-section-1 .cover-title-h2 {
    font-weight: 700;
    margin-bottom: 10px;
    text-align: center;
}

.sprt-section-1 .cover-title-p {
    color: #fff !important;
    margin: 0;
    text-align: center;
}

.sprt-card-title {
    font-weight: 700;
    font-family: var(--font-family);
    color: #000;
    margin: 0;
}

.horizontal-line {
    position: absolute;
    right: 0;
    top: 50px;
}

@media only screen and (max-width: 600px) {
    .horizontal-line {
        display: none !important;
    }
}

.sprt-card-p-height {
    height: 120px;
}

.sprt-section-3 .button2 {
    width: 100%;
    display: block;
    margin: 0;
}

.sprt-section-4 .button2 {
    width: 100%;
    display: block;
    margin: 0;
}

.sprt-section-4 .sprt-card-p-height {
    height: 80px;
}

/* suport */

/* About us*/
.about-section-1 {
    background-image: url('https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/images%2FAbout%2Fabout-epi-banner.png?alt=media&token=f866adfe-22bb-4dd5-8efa-b739271467e2');
    height: 500px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    position: relative;
    color: #fff;
    z-index: 0;
}

.about-section-1::after {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    background: #0059ff;
    opacity: 0.5;
    z-index: -1;
}
/* About us */

/* Shipping*/
.ship-section-1 {
    background-image: url('../../assets/img/images/shipping-banner.png');
    height: 500px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    position: relative;
    color: #fff;
    z-index: 10;
}

.ship-section-1::after {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    background: #0059ff;
    opacity: 0.5;
    z-index: -1;
}

.ship-section-1 .image-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    color: #fff;
}

.ship-section-1 .cover-title-h2 {
    font-weight: 700;
    margin-bottom: 10px;
    text-align: center;
}

.ship-section-1 .cover-title-p {
    color: #fff !important;
    margin: 0;
    text-align: center;
}

.ship-table {
    border-color: transparent;
    border-style: solid;
    border-width: initial;
}

.ship-table th {
    font-size: 18px;
    font-weight: var(--main-font-weight);
    font-family: var(--font-family);
    color: #4A4A6B;
}

.ship-table tr {
    border-color: transparent;
    border-style: solid;
    border-width: initial;
}

.ship-table td:nth-of-type(even) {
    font-size: 15px;
    font-weight: 500;
    font-family: var(--font-family);
    color: #4B5976
}

.ship-table td:nth-of-type(odd) {
    font-size: 15px;
    font-weight: 600;
    font-family: var(--font-family);
    color: #4B5976
}

/* .ship-s4-table th, td{
    color: #000 !important;
} */
.ship-s4-table td {
    color: #000 !important;
    font-weight: 600 !important;
}

/* Shipping*/
/* productregistration */
.pr-section-1 {
    background-image: url('../../assets/img/images/productregistration-banner.png');
    height: 500px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    position: relative;
    color: #fff;
    z-index: 10;
}

.pr-section-1::after {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    background: #0059ff;
    opacity: 0.5;
    z-index: -1;
}

.pr-section-1 .image-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    color: #fff;
}

.pr-section-1 .cover-title-h2 {
    font-weight: 700;
    margin-bottom: 10px;
    text-align: center;
}

.pr-section-1 .cover-title-p {
    color: #fff !important;
    margin: 0;
    text-align: center;
}

/* productregistration */

/* TechSupportNew */
.tsn-section-1 {
    background-image: url('../../assets/img/images/techsuport-banner.png');
    height: 500px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    position: relative;
    color: #fff;
    z-index: 10;
}

.tsn-section-1::after {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    background: #0059ff;
    opacity: 0.5;
    z-index: -1;
}

.tsn-section-1 .image-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    color: #fff;
}

.tsn-section-1 .cover-title-h2 {
    font-weight: 700;
    margin-bottom: 10px;
    text-align: center;
}

.tsn-section-1 .cover-title-p {
    color: #fff !important;
    margin: 0;
    text-align: center;
}

.loginbtn {
    padding: 15px 20px;
    width: 100%;
}

.signin-btn {
    padding: 15px 50px;
}

/* TechSupportNew */

/* Request Software & Manuals */
.rsm-section-1 {
    background-image: url('../../assets/img/images/request-software-manual-banner.png');
    height: 500px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    position: relative;
    color: #fff;
    z-index: 10;
}

.rsm-section-1::after {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    background: #0059ff;
    opacity: 0.5;
    z-index: -1;
}

.rsm-section-1 .image-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    color: #fff;
}

.rsm-section-1 .cover-title-h2 {
    font-weight: 700;
    margin-bottom: 10px;
    text-align: center;
}

.rsm-section-1 .cover-title-p {
    color: #fff !important;
    margin: 0;
    text-align: center;
}

.rsm-form-title {
    font-size: 18px;
    font-weight: 600;
    color: #4A4A6B;
    ;
}

/* Request Software & Manuals */

/* BugTracking */
.bt-section-1 {
    background-image: url('../../assets/img/images/bugtraking-banner.png');
    height: 500px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    position: relative;
    color: #fff;
    z-index: 10;
}

.bt-section-1::after {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    background: #0059ff;
    opacity: 0.5;
    z-index: -1;
}

.bt-section-1 .image-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    color: #fff;
}

.bt-section-1 .cover-title-h2 {
    font-weight: 700;
    margin-bottom: 10px;
    text-align: center;
}

.bt-section-1 .cover-title-p {
    color: #fff !important;
    margin: 0;
    text-align: center;
}

.bt-s2 {
    background-color: #F8FAFD;
}

.bt-text-area {
    background-color: #fff;
    max-height: 100% !important;
}

/* BugTracking */

/* drag drop file */
.drag-drop-main {
    position: relative;
}

.drag-drop-field {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 200px;
}

.MuiDropzoneArea-root {
    background-color: transparent !important;
    min-height: 237px !important;
}

.MuiDropzoneArea-text {
    visibility: hidden;
}

.MuiDropzoneArea-icon {
    visibility: hidden;
}

/* drag drop file */

/* SUPPORT INQUIRY */
.si-section-1 {
    background-image: url('../../assets/img/images/support-banner-img.png');
    height: 500px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    position: relative;
    color: #fff;
    z-index: 10;
}

.si-section-1::after {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    background: #0059ff;
    opacity: 0.5;
    z-index: -1;
}

.si-section-1 .image-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    color: #fff;
}

.si-section-1 .cover-title-h2 {
    font-weight: 700;
    margin-bottom: 10px;
    text-align: center;
}

.si-section-1 .cover-title-p {
    color: #fff !important;
    margin: 0;
    text-align: center;
}

.si-phone-text {
    margin-top: 0px !important;
}

.si-phoneinpputfeild {
    margin-top: 0px !important;
}

/* SUPPORT INQUIRY */

/* getquote */
.gq-section-1 {
    background-image: url('../../assets/img/images/getquote-banner.png');
}

.gq-s2 .form-group {
    width: 100%;
}

/* getquote */

/* View quote */
.vq-ctone {
    font-size: 20px;
}

.vq-cttow {
    font-size: 15px;
}

.vq-info-icon {
    width: 30px;
    height: 30px;
    margin-right: 5px;
}

.vq-body-row .commontitlethree {
    font-size: 16px;
}

/* View quote */

/* common column */
@media only screen and (max-width: 600px) {
    .common-column {
        justify-content: center;
    }
}

/* common column */

/* accordation */
.maintanance-accordation .MuiAccordionSummary-root {
    background-color: #EBF2FF !important;
    color: #4B5976 !important;
    font-weight: 600;
    border: 2px solid #4B5976;
}

.maintanance-accordation .MuiSvgIcon-root {
    font-size: 20px;
    color: #4B5976;
}

/* accordation */

.registered-new {
    position: absolute;
    top: 18px;
    right: 20px;
    font-size: 18px;
    color: #fff;
    display: flex;
    align-items: center;
}

.fa-plus {
    border: 1px solid #fff;
    border-radius: 50%;
    padding: 3px;
    margin-right: 5px;
    font-size: 30px;
}

@media only screen and (max-width: 600px) {
    .registered-new {
        font-size: 15px;
    }

    .fa-plus {
        border: 1px solid #fff;
        border-radius: 50%;
        padding: 1px;
        margin-right: 5px;
        font-size: 20px;
    }
    .common-main h2 {
        font-size: 20px;
    }
}

.check-main {
    margin-top: 15px;
}

.sign-in-Name-input{
    padding: 15px !important;
}

.quite-details-text{
    color: #fff;
    font-size: 1.5rem;
    font-weight: 600;
    margin-top: 0px !important;
    margin-bottom: 0;
}

.qoute-2nd-rw{
    width: 750px;
    color: #fff;
}
.qoute-2nd-rw ul li{
    list-style-type: none;
    display: inline;
}
.qoute-2nd-rw a{
    color: white;
}
.quoute-2d-element-main{
   color: white;
}

.quoute-2d-element-icon{
    font-size: 20px;
    color: white;
    margin: 0px 10px;
}

@media only screen and (max-width: 750px) {
    .qoute-2nd-rw{
        width: 100%;

    }
}

.quit-details-main-row {
    background-color: var(--main-color);
    margin: 0;
    border-radius: 5px 5px 0px 0px;
}

.prod_disc_strong {
    font-weight: 500;
}


/* -----new 37 pages-------------------------------------------------- */
/* -------------------------------------------------------------------- */

/* common banner style */
.commonbanner {
    height: 500px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    position: relative;
    color: #fff;
    z-index: 0;
}

.commonbanner::after {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    background: #0059ff;
    opacity: 0.35;
    z-index: -1;
}

.commonbanner .image-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    color: #fff;
}

.commonbanner .cover-title-h2 {
    font-weight: 700;
    margin-bottom: 10px;
    text-align: center;
}

.commonbanner .cover-title-p {
    color: #fff !important;
    margin: 0;
    text-align: center;
}

/* common banner style */

/* blog catagory start */
.blogCat-section-1 {
    background-image: url('../../assets/img/images/aboutUs-coverpage.png');
    height: 500px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    position: relative;
    color: #fff;
    z-index: 10;
}

.blogCat-section-1::after {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    background: #0059ff;
    opacity: 0.5;
    z-index: -1;
}

.blogCat-section-1 .image-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    color: #fff;
}

.blogCat-section-1 .cover-title-h2 {
    font-weight: 700;
    margin-bottom: 10px;
    text-align: center;
}

.blogCat-section-1 .cover-title-p {
    color: #fff !important;
    margin: 0;
    text-align: center;
}

.bc-card {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
}

/* blog catagory end */

/* blog single article start */
.bsaSection2 {
    background-color: #fff;
}

.bsaSection-3 {
    background-color: #EBF2FF;
}

.bsa-img {
    height: 350px;
    object-fit: contain;
    border-radius: 10px;
}

.bsaSection4 {
    background-color: #fff;
}

.bsaSection5 {
    background-color: #EBF2FF;
}

.bsa-circle-img {
    width: 80px;
    height: 80px;
    border-radius: 50%;
}

/* blog single article end */

/* careers job single start */

.blogCat-section-1 {
    background-image: url('../../assets/img/images/aboutUs-coverpage.png');
    height: 500px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    position: relative;
    color: #fff;
    z-index: 10;
}

.blogCat-section-1::after {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    background: #0059ff;
    opacity: 0.5;
    z-index: -1;
}

.blogCat-section-1 .image-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    color: #fff;
}

.blogCat-section-1 .cover-title-h2 {
    font-weight: 700;
    margin-bottom: 10px;
    text-align: center;
}

.blogCat-section-1 .cover-title-p {
    color: #fff !important;
    margin: 0;
    text-align: center;
}

.cjsSection2 {
    background-color: var(--white-color);
}

.cjsSection3 {
    background-color: var(--whiteBlue-color);
}

.cjsSection3 li {
    font-size: 15px;
    font-weight: 500;
    font-family: var(--font-family);
    color: #4B5976;
    line-height: 40px;
}

.cjsSection4 a {
    font-size: 15px !important;
}

/* careers job single end */

/* careers job apply start */
.CareerApply-section-1 {
    background-image: url('../../assets/img/images/CarrerApplyBanner.png');
}

.CareerApply-section-2 {
    background: #fff;
}

/* careers job apply start */

/* Documents Download start*/
.DocDowalods-section-1 {
    background-image: url('../../assets/img/images/DocumentDownloadsBanner.png');
}

.DDDAccordation .MuiPaper-root {
    box-shadow: 0 0 !important;
}

.DDDAccordation .MuiAccordionSummary-root {
    background-color: transparent !important;
    border-bottom: 2px solid #A2AABA;
}

.DDDAccordation .MuiSvgIcon-root {
    color: #253249 !important;
    font-size: 40px !important;
    font-weight: 300 !important;
}

.DDDFaFileAlt {
    font-size: 20px;
    margin-right: 10px;
}

@media only screen and (max-width: 600px) {
    .ddacordation-table-div {
        overflow-x: auto !important;
    }
}


/* Documents Download end*/


/* Privacy Inquiry start */
.PrivacyInc-section-1 {
    background-image: url('../../assets/img/images/PrivacyInqueryBanner.png');
}

/* Privacy Inquiry end */

/* Legal Privacy Policy start*/
.LegalPP-section-1 {
    background-image: url('../../assets/img/images/LegalPrivacyPolicyBanner.png');
}

/* Legal Privacy Policy end*/

/* Legal Conditions of Sale start */
.LegalCS-section-1 {
    background-image: url('../../assets/img/images/ConditionsofSaleBanner.png');
}

/* Legal Conditions of Sale end */

/* White Compressed Air start */
.WPCompressedAirSection-1 {
    background-image: url('../../assets/img/images/CompressedAirBanner.png');
}

.DownloadPDfbtn-div {
    position: relative;
    text-align: end;
}

.DownloadPDfbtn-icon {
    position: absolute;
    top: 1px;
    left: 8px;
    font-size: 15px;
    color: var(--main-color);
}

.DownloadPDfbtn:hover .DownloadPDfbtn-icon {
    color: #fff !important;
}

/* White Compressed Air end */

/* HVAC start */
.HVACSection-1 {
    background-image: url('../../assets/img/images/HvacBanner.png');
}

/* HVAC end */

/* Landfill start */
.LandFillSection-1 {
    background-image: url('../../assets/img/images/Landfillbanner.png');
}

/* Landfill end */

/* Natural gas start */
.NGSection-1 {
    background-image: url('../../assets/img/images/NaturalGasBanner.png');
}

.natrualgas-table td:nth-child(even) {
    background-color: #fff !important;
}

/* Natural gas end */

/* Wastewater start */
.WWaterSection-1 {
    background-image: url('../../assets/img/images/waterwasteBanner.png');
}

/* Wastewater end */

/* FlueGas start */
.FlueGasSection-1 {
    background-image: url('../../assets/img/images/FlueGasBAnner.png');
}

.FlareGasSection-1 {
    background-image: url('../../assets/img/images/FlareGasBanner.png');
}

/* FlueGas end */

/* Sitemap start */
.SiteMapSection-1 {
    background-image: url('../../assets/img/images/SitemapBanner.png');
}

.SiteMapSection2 h5 {
    font-size: 14px;
    font-weight: 600;
    font-family: var(--font-family);
    color: #4A4A6B;
    margin: 0;
    margin-bottom: 10px !important;
    position: relative;
}

.SiteMapSection2 h4 {
    font-size: 20px;
    font-weight: var(--main-font-weight);
    font-family: var(--font-family);
    color: #4A4A6B;
    margin: 0;
    margin-bottom: 10px !important;
    position: relative;
}

.SiteMapSection2 h4::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 3px;
    left: 0;
    top: 37px;
    z-index: -1;
    background: #E2E2E2;
}

.SiteMapSection2 a {
    color: var(--main-color);
    font-weight: 600;
}

.SiteMapSection2 ul {
    list-style-type: none;
    padding-left: 0;
}

.SiteMapSection2 li:first-child {
    margin-top: 30px;
}

.SiteMapSection2 li {
    margin-top: 10px;
}

/* Sitemap end */

/* BECOME A DISTRIBUTOR Repo */
.BDRepSection-1 {
    background-image: url('../../assets/img/images/becomedistributorrepBanner.png');
}

.BDR-circle-Img-Div {
    max-width: 300px;
    max-height: 300px;
}

.BDR-circle-Img {
    width: 100%;
    height: 100%;
}

@media only screen and (max-width: 768px) {
    .BDRSection2Row {
        flex-direction: column-reverse;
    }
}

/* BECOME A DISTRIBUTOR Repo */


/* Gases We Flow start */
.GWFlowSection-1 {
    background-image: url('../../assets/img/images/GasesWeFlowBanner.png');
}

.GWFlowSection2 h5 {
    font-size: 22px;
    font-weight: var(--main-font-weight);
    font-family: var(--font-family);
    color: var(--main-color);
    cursor: pointer;
}

/* Gases We Flow end */

/* Media Kit start*/
.MKitSection-1 {
    background-image: url('../../assets/img/images/MediaKitBanner.png');
}

/* Media Kit start*/

/* Blog start */
.BlogSection-1 {
    background-image: url('../../assets/img/images/commonBanner.png');
}

/* Blog end */

/* Career Opportunities start*/
.COSection-1 {
    background-image: url('../../assets/img/images/careeropportunitiesBanner.png');
}

.COsection-3 h4 {
    font-size: 28px;
    font-weight: var(--main-font-weight);
    font-family: var(--font-family);
    color: var(--main-color);
    margin: 0;
}

/* Career Opportunities end*/

/* WHITE PAPERS start */
.WPection-1 {
    background-image: url('../../assets/img/images/WhitePapersBanner.png');
}

.WPSection-2 h5 {
    min-height: 50px;
}

.WPS2-card-p {
    height: 275px;
}

/* WHITE PAPERS end */

/* Find a Rep start */
.FRepSection-1 {
    background-image: url('../../assets/img/images/FindRepBanner.png');
}

.FRsearchMain {
    position: absolute;
    top: 0;
    right: 0;
    background-color: var(--main-color);
    height: 100%;
    width: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0px 5px 5px 0px;
    cursor: pointer;
}

.FRsearchIcon {
    color: white;
    font-size: 20px;
}

.FPfromControl {
    padding: 17px !important;
}
.FPSection3{
    /* max-height: 500px; */
    overflow-x: scroll;
}
.FPSection3-container:first-child {
    margin-top: 10px;
}
.FPSection3 h5 {
    font-size: 20px;
    font-weight: 700;
    color: var(--main-color);
    margin: 0;
}

.FPSection3P {
    color: #4B5976;
    font-weight: 600;
    font-size: 15px;
    margin: 0;
}

.FPSection3 .seprator {
    border-right: 1px solid #D6E4FF;
}

.FPIframeDiv iframe {
    width: 100%;
    height: 500px;
}

.FPSection3-container {
    width: 87%;
    margin-left: auto;
    margin-bottom: 15px;
}

@media only screen and (max-width: 768px) {
    .FPSection3-container {
        width: 100%;
        padding: 10px;
        margin-bottom: 20px;
    }
}

.FPSection4 {
    background-color: #F8FAFD;
}

/* Find a Rep end */

/* Single Rep start */
.SRepSection-1 {
    background-image: url('../../assets/img/images/SingleRepBanner.png');
}

/* Single Rep end */

/* inline start */
.InlineSection-1 {
    background-image: url('https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Not_Impo_Img%2Ftechnical_info%2Finstallation-for-inline-hero.png?alt=media&token=2f368ad9-1082-46a7-afda-254de9006f50');
}

.InlineS2Img {
    max-width: 700px;
}

/* inline start */

/* Insertion start */
.InsertionSection-1 {
    background-image: url('https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Not_Impo_Img%2Ftechnical_info%2Finstallation-for-insertion-hero.png?alt=media&token=25b0166c-fb31-43cb-b976-376408e8405a');
}

/* Insertion end */

/* SERVICE REQUEST start */
.SRNSection-1 {
    background-image: url('../../assets/img/images/SERVICEREQUESTBanner.png');
}

.SRNSection2 h4 {
    height: 50px;
}

.SRNSection2 p {
    height: 100px;
}

/* SERVICE REQUEST end */

/* Request RMA start*/
.ReRMASection-1 {
    background-image: url('../../assets/img/images/RMARequestBanner.png');
}

.ReRMASection2 h1 {
    font-size: 40px;
    font-weight: 900;
    margin: 0;
    color: #4B5976;
}

/* Request RMA end*/

/* TECHNICAL INFORMATION start */
.TISection-1 {
    background-image: url('https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Not_Impo_Img%2Ftechnical_info%2Fflow-meter-technical-information-hero.png?alt=media&token=57a94d91-af5a-4839-acd2-b6b9ea2bcea4');
}

.TISection2 h4 {
    height: 60px;
}

/* TECHNICAL INFORMATION end */

/* Agency Approvals & Certificates start */
.AACSection-1 {
    background-image: url('https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Not_Impo_Img%2Ftechnical_info%2Fagency-approvals-and-certificates-hero.png?alt=media&token=1e45a513-18a2-4530-8fe5-df6f74d2d896');
}

.AACSection2 td:nth-child(even) {
    background-color: #fff;
}

.AACSection2 td {
    text-align: start;
}

.AACSection2 button {
    color: var(--main-color);
    border: 1px solid var(--main-color);
    border-radius: 5px;
    padding: 10px 15px;
    font-weight: bold;
    /* margin-left: 20px; */
    background-color: transparent;
    font-size: 15px;
    margin-top: 10px;
}

.AACSection2 button:hover {
    color: #fff !important;
    background: linear-gradient(90deg, rgba(9, 92, 246, 1) 2%, rgba(1, 66, 188, 1) 74%);
}

.AACSection2 img {
    width: 130px;
    height: 100px;
    object-fit: contain;
}

.AACSection2 th {
    padding: 20px;
}

@media only screen and (max-width: 993px) {
    .AACSection2-Table-div {
        overflow-x: scroll;
    }

    .AACSection2 {
        width: 993px;
    }
}

/* Agency Approvals & Certificates end */

/* METER CONSIDERATIONS  start*/
.MeterConSection-1 {
    background-image: url('https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Not_Impo_Img%2Ftechnical_info%2Fflow-meter-considerations-hero.png?alt=media&token=a54a831d-1b54-4564-b490-38dbe2426793');
}
/* METER CONSIDERATIONS  end*/

/* METER CONFIGURATIONS start */
.MeterConfSection-1{
    background-image: url('https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Not_Impo_Img%2Ftechnical_info%2Fflow-meter-configurations-hero.png?alt=media&token=919bc44b-49a5-4994-b0d1-725c8e312623');
}
.MConfCard-main{
    border-top: 2px solid #E2E2E2;
    padding: 40px 0px;
}
/* METER CONFIGURATIONS end */

/* Technology Comparison start */
.TComSection-1{
    background-image: url('https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Not_Impo_Img%2Ftechnical_info%2Fmeter-technology-comparison-hero.png?alt=media&token=175dabcf-c945-436c-ba80-9be98d930068');
}
/* Technology Comparison end */

/* operational theory start*/
.OTSection-1{
    background-image: url('https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Not_Impo_Img%2Ftechnical_info%2Fflow-meter-operational-theory-hero.png?alt=media&token=8c72bb0a-98e2-4d4b-9b83-a8ccdfddd85b');
}

/* full guide start*/
.FullGuide-1{
    background-image: url('https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Not_Impo_Img%2Ftechnical_info%2Fa-full-guide-to-thermal-mass-flow-meters-hero.png?alt=media&token=a6269a35-85f5-4c45-b17e-2cb59eb68d68');
}
.OTimage{
    width: 150px;
}
/* operational theory end*/

/* Installation start */
.InstallationSection-1{
    background-image: url('https://firebasestorage.googleapis.com/v0/b/epiprofileimgs.appspot.com/o/Not_Impo_Img%2Ftechnical_info%2Fthermal-mass-flow-meter-installation-hero.png?alt=media&token=2f1f527c-1a3d-47a6-a118-52df18f9df2c');
}
.InstallationS3Card{
    width: 300px;
    background-color: #fff;
}
.InstallationS3Card img{
    width: 130px;
}
@media only screen and (max-width: 768px) {
    .InstallationS3Card{
        width: 100%;
    }
}
.installation-img{
    width: 600px;
    object-fit: contain;
}
/* Installation end */

/* Industrial Applications start */
.IASection-1{
    background-image: url('../../assets/img/images/IndustrialApplicationsBanner.png');
}
/* Industrial Applications end */

.prod_disc_strong {
    font-weight: 500;
}

.anchor {
    display: block;
    position: relative;
    top: -150px;
    visibility: hidden;
}

.anchor2 {
    display: block;
    position: relative;
    top: -80px;
    visibility: hidden;
}

.cadEmHeader {
    background-color: #095cf6;
    color: white;
}

/* Jay's Style Fixes */

.navbar {
    min-height: 50px;
    margin-bottom: 20px;
    padding: 0 20px;
}

.mainNav {
  display: flex;
  flex-direction: row;
  justify-content: center;
  max-width: 100%;
}

.mainNavCards.card {
  width: 23%;
  max-width: 23%;
  margin: 0 5px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  flex-grow: 1;
  height: unset;
}

.mainNavCards .card-body {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.flexible {
    flex: 1 1;
    margin-bottom: 0;
}

.noPaddingRow.row {
    width: 100%;
    margin: 0;
    --bs-gutter-x: 0;
    align-self: flex-start;
	  margin-top: auto;
}

.noPaddingRow div.col {
    padding: 0;
}

ul.nav-pills-info.nav-pills-just-icons.noPaddingLink.nav.nav-pills {
    display: inline-block;
    width: 100%;
}

.col ul.noPaddingLink li.nav-item a.betterLink {
    width: 100%;
    max-width: 100%;
    padding: 0;
    background-color: #fff;
}
.col ul.noPaddingLink li.nav-item a.betterLink:hover {
    background-color: #ebf2ff;
    border: 1px solid #095cf6;
}

.fixedCards.card {
  width: 100%;
  max-width: 100%;
  margin: 0 5px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  flex-grow: 1;
  height: 100%;
}

.fixedCards.card .btn {
  margin-top: auto;
}

.fixedCards .contentFill {
  display: flex;
	flex-direction: column;
	flex: 1 1 300px;
}

.outerCardContainer {
    padding: 0;
}

.footerBlack {
    text-align: left;
}

.TISection2 .g-4.row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.TISection2 .col-md-6.col-lg-4 {
    display: flex;
    flex-direction: column;
}

.TISection2 h4 {
    height: auto;
    display: flex;
    text-align: center;
    flex-direction: column;
}

p.commonParaFour {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-height: 150px;
  flex: 1 1;
  margin-bottom: auto;
}

.TISection2 a.nav-link {
    margin-top: auto;
    height: auto;
    padding: 0;
}

.TISection2 a.nav-link button {
    margin-top: auto;
}

.TISection2 .p-3.shadow.rounded.text-center {
    flex: 1;
}

img.geometryImg {
    margin-bottom: 15px;
}

a.inlineBtn.nav-link {
    display: inline-block;
    width: auto;
    margin-right: 15px;
}

a.breadcrumbsLink {
    color: white;
    display: inline-block;
    text-transform: uppercase;
    padding: 0;
    padding-right: 5px;
}

a.breadcrumbsLink:hover {
    color: #0d6efd;
}

p {
  font-weight: 400;
}

.ddacordation-table {
    width: 100%;
}

.bg-epi-blue .navbar-brand p {
    font-weight: 600;
    font-size: .75em;
    /* max-width: 80%; */
    /* width: 90%; */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.bg-epi-blue a.navbar-brand {
    width: 100%;
    padding-top: 1rem;
}

.inline-block .nav-link {
  display: inline-block;
}

.CustomizerPage .bg-epi-blue .container {
    padding: 0;
}

i.productIcon {
    display: inline-block;
    width: 30px;
    margin-right: 10px;
}

i.animated {
  transition: width 1s, height 1s, transform 1s;
}

i.animated:hover {
  /* transform: rotate(180deg); */
  animation-name: bounce-7;
  animation-timing-function: cubic-bezier(0.280, 0.840, 0.420, 1);
  animation-duration: 1.5s;
  animation-iteration-count: 1;

}

@keyframes bounce-7 {
    0%   { transform: scale(1,1)      translateY(0); }
    10%  { transform: scale(1.1,.9)   translateY(0); }
    30%  { transform: scale(.9,1.1)   translateY(-8px); }
    50%  { transform: scale(1.05,.95) translateY(0); }
    57%  { transform: scale(1,1)      translateY(-1px); }
    64%  { transform: scale(1,1)      translateY(0); }
    100% { transform: scale(1,1)      translateY(0); }
}

.no-bg {
  background-color: #fff !important;
}

.mailChimpForm input {
  background-color: white;
  height: 48px;
  width: 65%;
  border-width: 1px 0px 1px 1px;
  border-top-style: solid;
  border-right-style: initial;
  border-bottom-style: solid;
  border-left-style: solid;
  border-top-color: rgb(227, 227, 227);
  border-right-color: initial;
  border-bottom-color: rgb(227, 227, 227);
  border-left-color: rgb(227, 227, 227);
  border-image: initial;
  border-radius: 6px 0px 0px 6px;
  margin-right: 0px;
  position: relative;
  flex: 1 1 auto;
  min-width: 0;
  padding: 10px !important;
  color: #2c2c2c;
}

.mailChimpForm button {
  border-radius: 0px 6px 6px 0px;
  border-left: 0px;
  margin-left: 0px;
  background-color: #0561FF;
  color: #FFFFFF;
  font-size: 1em;
  padding: 15px;
  font-weight: 400;
  border: none;
  width: 35%;
}

.mailChimpMessage {
    font-size: 20px;
    padding-bottom: 20px;
    font-weight: 700;
}

.selectedFilters h4 {
    margin: 0;
    padding-bottom: 10px;
    font-size: 20px;
    font-weight: bold;
    color: #000628;
    text-transform: capitalize;
    padding-left: 10px;
}

.selectedFilters span {
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    margin-top: 25px;
    display: block;
    padding-left: 10px;
}

.selectedFilters .left {
    width: 66%;
    display: inline-block;
}

.selectedFilters .right {
    width: 33%;
    display: inline-block;
}

.selectedFilters button.btn.btn-secondary {
    float: right;
    border: 2px solid #0361ff;
    background-color: white;
    color: #0361ff;
    font-size: 14px;
    font-weight: 700;
    margin: 0;
}

.selectedFilters button.btn.btn-secondary:hover {
    background-color: #0361ff;
    color: #fff;
}

a.nav-link.noPadding {
    padding: 0;
}

.productImageGallery {
  width: 100%;
  padding-top: 25px;
}

.productDropdown {
  padding-top: 0px;
  min-height: 775px;
  height: auto;
  display: block;
}

.productDropdown .container {
    height: auto;
    min-height: 715px;
}

.productDropdown .card {
  margin-bottom: 0px;
}

footer.footer.footer-header-menu {
    height: 50px;
    position: relative;
    bottom: 0;
    width: 100%;
    margin-top: 10px;
    display: block;
}

a.allProductsLink.nav-link {
    background-color: #095CF6;
    text-align: center;
    width: 100%;
}

a.allProductsLink.nav-link p {
    color: #fff;
    font-size: 1rem;
}

h5.costTitle.card-title {
    text-transform: uppercase;
    font-weight: 500;
    color: #4b5976;
    font-size: 20px;
    padding-bottom: 10px
}

.costLogin p {
    font-size: 18px;
    color: #dc3545;
    font-weight: 500;
}

.costPrice p {
    font-size: 28px;
    color: #095CF6;
    font-weight: 600;
}

p.costApproval {
    font-size: 18px;
    color: #df4554;
    font-weight: 600;
}

.calcButton, .calcButton:focus {
    color: #0561ff;
    background: none;
    border: 2px solid #0561ff;
    font-size: 14px;
    font-weight: 600;
}

.calcButton:hover, .calcButton:active {
    color: #fff;
    background: #0561ff;
    border: 2px solid #0561ff;
}

.calcReset {
  color: #4b5976;
  background: none;
  border: 2px solid #4b5976;
  font-size: 14px;
  font-weight: 500;
  padding: 10px 10px;
}

.calcReset:hover {
  color: #fff;
  background: #4b5976;
  border: 2px solid #4b5976;
}

.calcResultsRow {
    padding-top: 15px;
    padding-bottom: 10px;
}

.calcInfo {
    font-size: 24px;
    font-weight: 500;
}

.calcResult {
    font-size: 24px;
    font-weight: 500;
    color: #0561ff;
}

.calcError {
  font-size: 18px;
  color: #dc3545;
  font-weight: 600;
  padding-top: 15px;
}

.saveQuoteButtonZoom {
    color: #dc3545;
    border: 2px solid #dc3545;
    font-weight: 600;
    font-size: 16px;
    padding: 10px 15px!important;
    width: 100%;
}

.saveQuoteButtonZoom:hover {
    color: #fff;
    background-color: #dc3545;
}

.costModelTitle {
    text-transform: uppercase;
    font-weight: 500;
    color: #4b5976;
    font-size: 16px;
}

.costModelNum {
    font-weight: 700;
    font-size: 14px;
}

.priceBox {
    max-width: 275px;
    margin: 0 auto;
}

.modelImage img {
    width: 100%;
    max-width: 200px;
    margin: 0 auto;
    padding-bottom: 15px;
}

a.contactLink.nav-link {
    position: relative;
    width: auto;
    max-width: 175px;
    background: #ffffff;
    border-radius: 5px;
    border: 2px solid #0561FF;
    color: #0561FF;
    font-weight: 500;
    text-align: center;
    float: right;
    padding: 5px 10px;
}

a.contactLink.nav-link:hover {
    background: #0561FF;
    border: 2px solid #0561FF;
    color: #fff;
}

.quote-nav-options {
    color: #fff;
    font-size: .9rem;
    font-weight: 500;
}

.quote-nav-options ul li {
    list-style: none;
    display: inline-block;
    padding: 20px 10px;
}

.quote-nav-options ul li a:hover, .quote-nav-options ul li a svg:hover {
    color: #949CAD !important;
    cursor: pointer;
}

.pagination .page-item.active > .page-link, .pagination .page-item.active > .page-link:focus, .pagination .page-item.active > .page-link:hover {
    background-color: #0561ff;
}

.qPagination ul.pagination.justify-content-center {
    font-weight: 600;
}

.meterImg {
    max-height: 150px;
}

.sidebar-main {
    font-family: var(--font-family);
    width: 420px !important;
    position: relative !important;
    right: 0;
    top: 0;
    left: 10%;
    padding-left: 10px;
    float: right;
}

.form-control, .form-control-plaintext {
    font-size: 1rem;
    font-weight: 500;
}

textarea.form-control {
    border: 1px solid #E3E3E3;
    /* border-bottom: 1px solid #E3E3E3; */
    border-radius: 5px;
}

textarea.form-control:focus {
    border: 1px solid var(--main-color);
    box-shadow: none;
    outline: 0 !important;
    color: #2c2c2c;
}

.phoneinpputfeild.form-control.PhoneInput {
    padding: 0 !important;
    font-size: 1rem;
    font-weight: 500;
    border: 1px solid #e3e3e3;
    color: #a8a8a8;
}

.phoneinpputfeild.form-control.PhoneInput input {
    font-size: 1rem;
    font-weight: 500;
}

.phoneinpputfeild.form-control.PhoneInput:active {
    border-color: #0561ff;
}

.btn-gradient {
    border: 1px solid var(--main-color);
    font-size: 14px;
    font-weight: 600;
    padding: 10px 35px;
    color: #fff !important;
    background: rgb(10, 96, 255);
    background: linear-gradient(90deg, rgba(10, 96, 255, 1) 7%, rgba(0, 65, 186, 1) 75%);
}

.cCheckout a {
    width: 100%;
    font-size: .75rem;
    padding: 15px;
    margin-bottom: 5px;
}

.cMeterInfo p.title {
    margin-bottom: 0px;
    padding: 0;
    font-size: 1.2rem;
}

.cMeterInfo p.price {
    font-weight: 600;
    padding-top: 10px;
}

.cMeterInfo .form-control {
    margin-top: 5px;
    border-color: #b7bdc8;
    font-size: .8rem;
    font-weight: 600;
}

.cMeterInfo .accordion-item h2 {
    margin-top: 5px;
    background-color: #fff;
}

.cMeterInfo button.accordion-button.collapsed {
    border: 1px solid #b7bdc8;
    border-radius: 5px;
    margin: 10px auto;
}

.qTable td {
    padding: 15px 10px;
}

.qTable tr {
    background-color: #fff;
}

.qTable tr:hover {
    background-color: #f1f5fb !important;
}

.qTable tr:nth-child(even) {
    background-color: #f9f9f9;
}

.cTable tr:hover {
    background-color: #f5f5f5 !important;
}

.cCheckout .btn {
    max-width: 200px;
    margin-left: 10px;
    float: right;
}

.btn-text {
    background: transparent;
    color: #095cf6 !important;
    font-weight: 700;
    padding: 5px;
    font-size: .8rem;
    padding: 10px 8px;
    margin-bottom: 5px;
    border-radius: 5px;
}

.btn-text:hover {
    color: #010f29 !important;
}

.cSpecs a.btn.btn-text:hover {
    background: none;
    border: none;
    box-shadow: none;
}

.btn-text i.fa.fa-plus {
  border: none;
  padding: 0px;
  margin-right: 5px;
  font-size: .8rem;
}

.btn-outline {
    background: transparent;
    color: #095cf6;
    border: 2px solid #095cf6;
    margin-right: 5px;
    font-weight: 700;
    padding: 5px;
    font-size: .8rem;
}

.btn-outline:hover {
    background: #095cf6;
    color: #fff;
    border-color: #095cf6;
}

button.btn.disabled {
    border-color: white;
    background-color: white;
}

.qTable .form-control {
    background: white;
    border: 1px solid #b7bdc8;
    border-radius: 5px;
    font-weight: 500;
    font-size: .8rem;
}

a.cartIcon.nav-link sub {
    font-size: 0.6rem;
    font-weight: 600;
    padding: 2px 4px;
    background-color: #0660ff;
    color: white;
    border-radius: 5px;
}

a.cartIcon.nav-link svg {
    font-size: 1rem;
    margin-right: 1px;
}

p.betaText {
    max-width: 750px;
    margin: 0 auto;
    padding-bottom: 25px;
}

.common-main {
    padding: 0;
}

a.btnNav {
    color: white;
    font-size: .9rem;
    font-weight: 600;
}

.btnNav svg {
    height: 17px;
    width: 17px;
    margin: 0px;
    margin-right: 7px;
}

.btnNav svg.svg-inline--fa.fa-w-14 {
    height: 17px;
    width: 17px;
    margin: 0px;
    margin-right: 7px;
}

svg.editConfig {
    margin-left: 15px;
    font-size: 1rem;
    color: #095cf6;
}

.listRow {
    padding: 0 20px;
}

.table .thead-dark th {
    color: #fff;
    background-color: #012e82;
    border-color: #012e82;
}

.row.filters {
    font-weight: 500;
}

th.tHeadText:nth-child(0) {
    display: none;
}

span.backBtn {
    font-weight: 500;
    padding-left: 20px;
    margin: 10px 0px;
    display: block;
    font-size: .9rem;
}

span.backBtn svg {
    color: #4c5976;
    font-size: .85rem;
}

li.page-item {
    margin: 0 2px;
}

a.hyperlink {
  color: #0561ff !important;
}

a.hyperlink:hover {
  color: #000e29 !important;
  cursor: pointer;
}

.pagination .page-item a.page-link {
    border: 1px solid #0561ff;
    color: #0561ff;
    font-weight: 600;
}

.pagination .page-item a.page-link:hover {
    border: 1px solid #ebf2ff;
    color: #fff;
    background-color: #0561ff;
}

.pagination .page-item.disabled > .page-link {
    border: 1px solid #fff;
    color: #888c97;
    background-color: #fff;
}

@media screen and (max-width: 992px) {
  .priceBox {
      max-width: 100%;
      margin: 0 auto;
      display: flex;
      list-style-type: none;
      flex-direction: row-reverse;
      align-content: center;
      flex-grow: 1;
      justify-content: center;
      grid-gap: 20px;
  }
  .priceBox .card {
      width: 50%;
      justify-content: space-between;
      align-items: center;

  }

}

/* iPad Screens */

@media screen and (max-width: 992px) {

  .heading-text {
      margin-top: 20px;
  }
  .section-1 button {
    margin-bottom: 50px;
  }

  .button2 {
      padding: 15px 20px;
      width: 100%;
  }

  .btn-lg[class*="btn-outline-"] {
    padding: 10px 35px;
  }


}


/* Small Screens */

@media screen and (max-width: 767px) {

  .row {
      padding: 0 15px;
  }

  .section-1 {
    margin-top: 0px;
    padding-top: 0px;
    padding-bottom: 80px;
  }

  .heading-secondary {
    margin-bottom: 45px;
   }

  .outerCardContainer {
      margin-bottom: 25px;
  }

  .responsiveMarginB25 {
    margin-bottom: 25px;
  }

  .imgResponsive {
      width: 100%;
      margin: 0px;
      max-width: 100%;
      margin-bottom: 15px;
      border-radius: 5px;
  }

  .fixedCards img.card-img-top {
      max-width: 300px;
      margin: 0 auto;
  }

  .section-10 .img-div {
      background-color: #EEEEEE;
      border-radius: 5px;
  }

  .footerBlack {
    text-align: center;
  }

  .footerBlack nav {
      margin: 0 auto;
      float: none;
      padding-top: 10px;
  }

  div#copyright {
    text-align: center;
    padding-top: 25px;
  }

  .footer .input-group {
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    align-items: stretch;
    width: 100%;
  }

    footer.footer.footer-default.footerContactInfo {
      height: auto;
  }

  .button2 {
      padding: 10px 10px;
      width: 100%;
      font-size: .8em !important;
  }

  .qHeading {
      text-align: right;
  }

}
